<template>
	<div ref="organisations">
		<div class="eng-tab-section__info">
			<span class="eng-tab-section__info__item">
				<VLink
					size="small"
					weight="semibold"
					@click.stop.prevent="
						openInfoAlert('What does this mean?', 'ewg-snp-info')
					"
				>
					<img
						src="@/assets/imgs/icons/icon-info.svg"
						class="question"
						alt="info icon"
					/>What does this mean?
				</VLink>
			</span>
			<span class="eng-tab-section__info__item">
				<VLink
					size="small"
					weight="semibold"
					@click.stop.prevent="
						openInfoAlert(
							'Got questions about this?',
							'ewg-snp-question'
						)
					"
				>
					<img
						src="@/assets/imgs/icons/icon-question.svg"
						class="question"
						alt="question icon"
					/>Got questions about this?
				</VLink>
			</span>
		</div>
		<div class="eng-tab-section__content__filter active_details">
			<div
				v-if="types.length"
				class="eng-tab-section__content__filter__item"
			>
				<VText size="big" classes="title" weight="bold">
					Show me:
				</VText>
				<VDefaultField className="form__group__inline">
					<template #field>
						<div
							class="form__group__inner"
							v-for="type in types"
							:key="type.slug"
						>
							<VRadio
								:id="type.slug"
								:label="type.name"
								:input-value="type.slug"
								v-model="snpFilters.type"
								class="radio"
							/>
						</div>
					</template>
				</VDefaultField>
			</div>
			<div
				v-if="sectors.length"
				class="eng-tab-section__content__filter__item"
			>
				<VText size="big" classes="title" weight="bold">
					Related To:
				</VText>
				<VDefaultField class="form__group__inline">
					<template #field>
						<div
							class="form__group__inner"
							v-for="sector in sectors"
							:key="sector.slug"
						>
							<input
								type="checkbox"
								class="form__checkbox"
								:id="sector.slug"
								:value="sector.slug"
								:disabled="crossSectorSelected(sector)"
								v-model="snpFilters.sectors"
								@input="handleSectorSelect"
							/>
							<VLabel :for="sector.slug" class="label">
								{{ sector.name }}
							</VLabel>
						</div>
					</template>
				</VDefaultField>
			</div>
		</div>
		<div class="org-section__partners org-section__partners--policies">
			<OrganisationCard
				v-for="organisation in organisations"
				:key="organisation.slug"
				:ref="organisation.slug"
				:label="organisation.type"
				:organisation="organisation"
				:active="isActiveOrganisation(organisation)"
				@click="handleOrganisationClick"
				:id="organisation.slug"
			/>
		</div>
		<OrganisationOverview
			:margin="margin"
			@setActiveModal="setModal"
			v-if="activeOrganisation"
		/>
		<DataVisualViewModal
			ref="modal"
			:errors="errors"
			@close="setActiveModal"
			@onSubmit="submitModalForm"
			v-if="modal"
		/>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import debounce from 'lodash/debounce';

import {
	FETCH_SYSTEMS_AND_POLICES,
	FETCH_SECTORS,
	SAVE_VISUAL
} from '@/store/actions.type';

import { SET_ACTIVE_ORGANISATION } from '@/store/mutations.type';

import DataVisualViewModal from '@/modals/DataVisualViewModal';
import OrganisationOverview from '@/pages/LandingPage/sections/EngagingWithGovernment/SystemsAndPolicies/OrganisationOverview';

import Errors from '@/helpers/Errors';
import tooltipAlert from '@/mixins/tooltip-alert';

export default {
	name: 'SystemsAndPolicies',
	components: {
		DataVisualViewModal,
		OrganisationOverview
	},
	data() {
		return {
			modal: null,
			errors: new Errors(),
			types: [
				{ name: 'Everything', slug: 'everything' },
				{ name: 'Institutions', slug: 'institution' },
				{ name: 'Policies', slug: 'policy' },
				{ name: 'Programmes', slug: 'programme' }
			],
			margin: ''
		};
	},
	mixins: [tooltipAlert],
	watch: {
		snpFilters: {
			handler: debounce(function() {
				this.fetchData();
			}, 300),
			deep: true
		},
		'snpFilters.type': function() {
			this.$refs.organisations.scrollIntoView({ behavior: 'smooth' });
		},
		'ewgFilters.country': {
			handler: 'fetchSectors',
			immediate: true,
			deep: true
		}
	},
	computed: {
		...mapGetters(['snpFilters']),
		...mapState({
			activeOrganisation: state =>
				state.systemAndPolices.activeOrganisation,
			organisations: state =>
				state.systemAndPolices.organisations.filter(o => o.name),
			filters: state => state.systemAndPolices.filters,
			ewgFilters: state => state.ewg.ewgFilters,
			sectors: state => state.app.sectors
		})
	},
	beforeDestroy() {
		this.$store.commit(SET_ACTIVE_ORGANISATION, null);
	},
	methods: {
		async fetchData() {
            const queryOrganisation = this.$route.query.organisation;

			this.$store.commit(SET_ACTIVE_ORGANISATION, null);
            this.setQueryParams();

			await this.$store.dispatch(FETCH_SYSTEMS_AND_POLICES, {
				country: this.ewgFilters.country.country_id,
				params: this.snpFilters
			});

			if (queryOrganisation && this.$refs[queryOrganisation]) {
				this.$refs[queryOrganisation][0].$el.scrollIntoView({
					behavior: 'smooth'
				});
							
				this.handleOrganisationClick(this.organisations.find(o => o.slug === queryOrganisation));
			}
		},
		async fetchSectors() {
			if (!this.ewgFilters.country) return;

			if (!this.sectors.length) {
				const params = this.snpFilters.type
					? {
							type: 'snp',
							sndType: this.snpFilters.type,
							country: this.ewgFilters.country.id
					  }
					: { type: 'snp', country: this.ewgFilters.country.id };

				await this.$store.dispatch(FETCH_SECTORS, params);
			}

			this.setParams();
		},
		async submitModalForm(form) {
			try {
				form = {
					...form,
					filters: this.$route.query,
                    type: 'snp'
				};
				await this.$store.dispatch(SAVE_VISUAL, form);

				this.setActiveModal();
				this.$toastr.s('Success', 'Successfully saved visual');
			} catch ({ response: { data } }) {
				this.errors.record(data);
			}
		},
        setQueryParams() {
			this.$router
				.replace({
					name: 'systems-and-policies',
					query: {
						...this.$route.query,
						type: this.snpFilters.type,
						sectors: this.snpFilters.sectors,
                        organisation: this.activeOrganisation ? this.activeOrganisation.slug : ''
					}
				})
				.catch(err => {});
        },
		setActiveModal(modal = null) {
			this.modal = modal;

			if (!modal) return;

			this.$nextTick(() => {
				this.$refs.modal.form.title = this.title;
				this.$refs.modal.title = this.title;
			});
        },
		isActiveOrganisation(organisation) {
			return (
				this.activeOrganisation &&
				this.activeOrganisation.slug === organisation.slug
			);
		},
		setParams() {
			let { sectors, type } = this.$route.query;

			if (sectors) {
				sectors = typeof sectors == 'object' ? sectors : [sectors];
			}

			this.snpFilters.sectors = sectors ? sectors : ['Cross Sector'];
			this.snpFilters.type = type ? type : 'everything';
		},
		handleOrganisationClick(organisation) {
			this.$store.commit(SET_ACTIVE_ORGANISATION, organisation);

			this.margin = document.getElementById(organisation.slug).offsetTop;
            this.setQueryParams();
		},
		setModal(modal) {
			if (!modal) return;

			this.modal = modal;

			this.$nextTick(() => {
				const title = `System and Policy / ${this.activeOrganisation.name}`;

				this.$refs.modal.form.title = title;
				this.$refs.modal.title = title;
			});
		},
		crossSectorSelected(sector) {
			return (
				sector.slug.includes('Cross Sector') &&
				this.snpFilters.sectors.includes('Cross Sector')
			);
		},
		handleSectorSelect({ target: { value } }) {
			if (
				this.snpFilters.sectors.length > 0 &&
				value === 'Cross Sector'
			) {
				this.snpFilters.sectors = ['Cross Sector'];
				return;
			}

			this.snpFilters.sectors = this.snpFilters.sectors.filter(
				sector => !sector.includes('Cross Sector')
			);
		}
	}
};
</script>

<style scoped>
.question {
	margin-right: 15px !important;
}
/deep/ .row-align {
	display: flex;
	flex-wrap: wrap;
}

@media only screen and (max-width: 991px) {
	/deep/ .row-align {
		flex-direction: column;
	}
}
</style>
