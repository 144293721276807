<template>
	<div
		class="eng-tab-section__content__details active-details"
		:style="{ marginTop: margin + 197 + 'px' }"
	>
		<div class="eng-tab-section__content__details__close cursor-pointer">
			<img
				src="@/assets/imgs/icons/icon-close.svg"
				alt=""
				@click="setActiveOrganisation"
			/>
		</div>

		<div class="eng-tab-section__content__details__header">
			<VHeading level="5">{{ organisation.name }}</VHeading>
			<VText weight="bold" v-if="organisation.resourceType"
				>Type: {{ organisation.resourceType }}</VText
			>
			<div class="organogram--btn" v-if="organisation.type === 'institution'">
				<!-- <VButton
					color="outline-black"
					class="button button--icon"
					@click.stop="$store.commit('setActiveModal', 'OrganogramModal')"
				>
					<img src="@/assets/imgs/icons/icon-hierarchy.svg" alt="" />
					Launch organogram for this institution
				</VButton> -->
			</div>
		</div>

		<div class="eng-tab-section__content__details__main">
			<div
				v-html="organisation.content"
				class="organisation-policy-content"
				key="organisation-content"
				v-if="organisation.content"
			></div>
			<div class="organisation-policy-content" key="no-content" v-else>
				No description.
			</div>
			<div
				v-if="showTabs"
				class="eng-tab-section__content__details__main__right"
			>
				<div class="mini-tab">
					<div class="mini-tab__tab">
						<VText
							v-for="tab in tabs"
							:key="tab"
							classes="mini-tab__tab__item"
							size="small"
							style="text-transform: capitalize"
							:class="activeTab === tab ? 'active' : ''"
							@click="activeTab = tab"
						>
							{{ tab === 'contacts' ? 'Key Contacts' : tab }}
						</VText>
					</div>
					<div class="mini-tab__content">
						<ul v-if="activeTab.includes('resource')">
							<li
								v-for="(resource, index) in organisation[activeTab]"
								:key="index"
							>
								<VText
									classes="mb-0"
									key="name"
									v-if="!resource.target.includes('internal') && !resource.link"
								>
									{{ resource.name }}
								</VText>

								<VLink
									size="regular"
									:href="getResourceLink(resource)"
									target="_blank"
									v-bind="
										resource.target.includes('download')
											? { download: resource.name }
											: {}
									"
									key="link"
									v-else
								>
									<div class="flex">
										<div class="mr-10 resources-text">{{ resource.name }}</div>
										<div>
											<img
												src="@/assets/imgs/icons/icon-download-white.svg"
												alt="download icon"
												key="download-icon"
												v-if="resource.target.includes('download')"
											/>
											<img
												src="@/assets/imgs/icons/icon-external-link-white.svg"
												alt="external link icon"
												key="external-link-icon"
												v-if="resource.target.includes('external')"
											/>
										</div>
									</div>
								</VLink>
							</li>
						</ul>
						<ul v-else>
							<div
								class="modal-section__card__content__list__item modal-item word--break mb-10"
								v-for="contact in organisation.contacts"
								:key="contact.id"
							>
								<div class="flex items-center">
									<div class="flex items-center">
										<img
											src="@/assets/imgs/icons/icon-arrow-point.svg"
											alt="icon arrow point"
											class="mr-10"
										/>

										<VText key="name" classes="mb-0 resources-text">
											<u>
												<span>{{ contact.position }}: </span>
												<span
													>{{
														contact.name
															.replace(/\s*$/, '')
															.split(' ')
															.slice(0, -1)
															.join(' ')
													}}
													<b>
														{{
															contact.name
																.replace(/\s*$/, '')
																.split(' ')
																.slice(-1)
																.join(' ')
														}}</b
													>
												</span>
											</u>
										</VText>
									</div>

									<div class="w-25 right">
										<a
											class="mr-10"
											target="_blank"
											:href="contact.socials.twitter"
										>
											<img
												v-if="contact.socials.twitter"
												src="@/assets/imgs/social/logo-twitter.svg"
												alt="twitter"
											/>
										</a>
										<a
											target="_blank"
											:href="contact.socials.linkedin"
											v-if="contact.socials.linkedin"
										>
											<img
												src="@/assets/imgs/social/logo-linkedin2.svg"
												alt="linkedin"
											/>
										</a>
									</div>
								</div>
							</div>
							<div class="mini-tab__content__social mt-20">
								<div
									v-if="
										organisation.socials.twitter ||
											organisation.socials.linkedin ||
											organisation.socials.facebook ||
											organisation.socials.instagram ||
											organisation.website
									"
								>
									<VText classes="mb-5" weight="bold" size="small"
										>Contact this organisation:</VText
									>

									<a
										target="_blank"
										v-if="organisation.socials.twitter"
										:href="organisation.socials.twitter"
									>
										<img
											src="@/assets/imgs/social/logo-twitter.svg"
											alt="twitter"
										/>
									</a>
									<a
										target="_blank"
										:href="organisation.socials.linkedin"
										v-if="organisation.socials.linkedin"
									>
										<img
											src="@/assets/imgs/social/logo-linkedin2.svg"
											alt="linkedin"
										/>
									</a>
									<a
										target="_blank"
										:href="organisation.socials.facebook"
										v-if="organisation.socials.facebook"
									>
										<img
											src="@/assets/imgs/social/logo-facebook.svg"
											height="17"
											alt="facebook"
										/>
									</a>
									<a
										target="_blank"
										:href="organisation.socials.instagram"
										v-if="organisation.socials.instagram"
									>
										<img
											src="@/assets/imgs/social/logo-instagram.svg"
											alt="instagram"
										/>
									</a>
									<a
										target="_blank"
										:href="organisation.website"
										v-if="organisation.website"
									>
										<img
											src="@/assets/imgs/icons/globe.svg"
											style="width: 17px"
											alt="globe"
										/>
									</a>
								</div>
							</div>
						</ul>
					</div>
				</div>
			</div>
		</div>

		<Actions @onSaveViewClick="$emit('setActiveModal', 'VisualModal')" />
	</div>
</template>

<script>
import DataVisualViewModal from '@/modals/DataVisualViewModal';
import { SET_ACTIVE_ORGANISATION } from '@/store/mutations.type';
import { mapState } from 'vuex';

export default {
	name: 'OrganisationOverview',
	components: { DataVisualViewModal },
	props: {
		margin: {
			default: ''
		}
	},
	data() {
		return {
			activeTab: null
		};
	},
	computed: {
		...mapState({
			rawOrganisation: state => state.systemAndPolices.activeOrganisation
		}),
		organisation() {
			return {
				...this.rawOrganisation,
				...{
					contacts: this.rawOrganisation.contacts.filter(contact => contact),
					resources: this.rawOrganisation.resources.filter(resource => resource)
				}
			};
		},
		tabs() {
			const labels = ['contacts', 'resources'];

			return labels.filter(
				tab => this.organisation[tab] && this.organisation[tab].length
			);
		},
		showTabs() {
			return (
				this.organisation[this.activeTab] &&
				(this.organisation.contacts.length ||
					this.organisation.resources.length)
			);
		}
	},
	watch: {
		organisation: 'setActiveTab'
	},
	mounted() {
		this.setActiveTab();
	},
	methods: {
		setActiveTab() {
			for (let i = 0; i < this.tabs.length; i++) {
				if (this.organisation[this.tabs[i]].length) {
					this.activeTab = this.tabs[i];
					return;
				}
			}
		},
		setActiveOrganisation() {
			this.$store.commit(SET_ACTIVE_ORGANISATION, null);
		},
		getResourceLink(link) {
			if (!link.target.includes('internal')) return link.link ? link.link : '#';

			if (link.type.includes('Organisation')) {
				return `/organisation-profile/${link.resource.slug}`;
			}

			if (
				link.type.includes('SystemAndPolicy') ||
				link.type.includes('StakeholderIndividual')
			) {
				return `/engaging-with-government/systems-and-policies?organisation=${link.resource.slug}&country=${link.resource.country.name}&sectors=${link.resource.sector.slug}`;
			}
		}
	}
};
</script>

<style scoped>
/deep/ .button--action div {
	width: 100%;
	margin: 0;
}
</style>
